.cookies {
  width: 100vw;
  background-color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  padding: 50px 30px;
  display: flex;
}

.cookies-title {
  font-size: var(--MediumFont);
  margin-right: auto;
  width: 65vw;
  line-height: 14.5px;
}

.cookies-link {
  text-decoration-line: underline !important;
}

.cookies-wrapper {
  display: flex;
}

.cookies__accept {
  width: 160px;
  height: 45px;
  background-color: #E7E5E8;
  color: black;
  font-size: var(--MediumFont);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-top: 3px;
}

.cookies__decline {
  width: 160px;
  height: 45px;
  border: 1px solid #E7E5E8;
  color: white;
  font-size: var(--MediumFont);
  text-transform: uppercase;
  margin-left: 40px;
  padding-top: 3px;
}

@media (max-width: 1600px) {
  .cookies-title {
    width: 50vw;
  }
}

@media (max-width: 1024px) {
  .cookies {
    display: flex;
    gap: 68px;
    flex-direction: column;
    align-items: center;
    padding: 45px 30px;
  }

  .cookies-title {
    width: 60vw;
    text-align: center;
    margin-left: auto;
    font-size: var(--MediumFont);
  }

  .cookies-wrapper {
    gap: 64px;
  }

  .cookies__accept {
    margin-bottom: 0px;
    padding-top: 5px;
  }

  .cookies__decline {
    margin-left: 0px;
    padding-top: 3px;
  }

  @media (max-width: 768px) {
    .cookies {
      gap: 40px;
      padding: 45px 20px;
    }

    .cookies-title {
      width: 100%;
      font-size: var(--MediumFont);
    }

    .cookies-wrapper {
      width: 100%;
      gap: 0px;
      justify-content: space-between;
    }
  }
}