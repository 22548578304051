.item__colors {
  width: 100vw;
  background-color: #D9D9D914;
  padding-top: 88px;
  padding-bottom: 88px;
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.item__colors-wrapper {
  width: 1400px;
}

.item__colors-title {
  font-size: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 80px;
}

.item__colors-img {
  height: 732px;
  margin-bottom: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.item__colors-navigate {
  display: flex;
  position: absolute;
  bottom: 22px;
  gap: 14px;
}

.item__colors-navigate-item {
  width: 10px;
  height: 10px;
  background-color: #ffffff61;
  cursor: pointer;
  position: relative;
}

.item__colors-navigate-item::before {
  content: '';
  position: absolute;
  top: -10px;
  /* Увеличиваем кликабельную область */
  left: -10px;
  /* Увеличиваем кликабельную область */
  right: -10px;
  /* Увеличиваем кликабельную область */
  bottom: -10px;
  /* Увеличиваем кликабельную область */
}

.item__colors-navigate-item-active {
  background-color: #ffffff;
}

.item__colors-discription {
  text-transform: uppercase;
  width: 95%;
  font-size: 16px;
  line-height: 23.2px;
  letter-spacing: 0.05em;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1500px) {

  .item__colors {
    padding-top: 58px;
    padding-bottom: 58px;
    margin-top: 120px;
  }

  .item__colors-wrapper {
    width: 936px;
  }

  .item__colors-title {
    font-size: 32px;
    margin-bottom: 56px;
  }

  .item__colors-img {
    height: 487px;
    margin-bottom: 64px;
  }

  .item__colors-discription {
    font-size: 13px;
  }


}