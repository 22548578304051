.inputs__title {
  font-size: 32px;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}

.inputs__form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 1078px;
}

.inputs__form-input {
  background-color: inherit;
  color: white;
  padding: 40px 15px 15px 15px;
  border: none;
  border-top: 2px solid #d9d9d933;
  text-transform: uppercase;
  font-size: 15px;
}

.inputs__form-input::placeholder {
  color: #D9D9D980;
}

.inputs__form-input.error::placeholder {
  color: red;
}

.inputs__form-label {
  border-top: 2px solid #d9d9d933;
  padding-top: 66px;
  font-size: 12px;
  color: #d9d9d980;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.inputs__form-label-wrapper {
  width: 80%;
}

.inputs__form-label input[type='checkbox'] {
  display: none;
}

.inputs__form-label .custom-checkbox {
  min-width: 25px;
  height: 25px;
  border: 1px solid #D9D9D980;
  background-color: inherit;
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
}

/* Состояние чекбокса, когда он активен */
.inputs__form-label input[type='checkbox']:checked+.custom-checkbox {
  background-color: #d9d9d9d5;
}

/* Состояние чекбокса при ошибке */
.inputs__form-label.error .custom-checkbox {
  border-color: red;
}

.inputs__form-label-link {
  text-decoration: underline !important;
}

.inputs__form-button {
  padding: 32px 64px;
  border: 1px solid rgb(73, 72, 72);
  color: white;
  margin-top: 60px;
  text-transform: uppercase;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background: #0000006b;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.16em;
  font-weight: 300;
}

@media (max-width: 1500px) {
  .inputs__title {
    font-size: 32px;
  }

  .inputs__form-input {
    padding: 40px 15px 15px 15px;
    font-size: 14px;
  }

  .inputs__form-button {
    font-size: 10px;
  }

}

@media (max-width: 1200px) {
.inputs {
margin-top: 40px;
}

  .inputs__title {
    font-size: 16px;
  }

  .inputs__form {
    margin-top: 40px;
    width: 736px;
  }

  .inputs__form-input {
    padding: 27px 10px 10px 10px;
    font-size: 10px;
  }

  .inputs__form-label {
    padding-top: 40px;
    font-size: 8px;
    line-height: 16px
  }

  .inputs__form-button {
    padding: 24px 48px 24px 48px;
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .inputs__form {
    margin-top: 40px;
    width: calc(100% - 40px);
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .inputs__form-label {
    padding-top: 40px;
    font-size: 8px;
    align-items: flex-start;
  }

  .inputs__form-label-wrapper {
    width: 90%;
  }

  .inputs__form-label .custom-checkbox {
    min-width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  .inputs__form-button {
    padding: 24px 48px 24px 48px;
    margin-top: 42px;
    width: 90%;
  }
}