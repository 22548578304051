.footer {
  margin-top: 200px;
}

.footer__link {
  font-size: 52px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #FFFFFFB2;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 250px;
  margin-left: 64px;
  margin-right: 64px;
}

.footer__info {
  display: flex;
  justify-content: space-between;
  margin-top: 240px;
  margin-left: 64px;
  margin-right: 64px;
  margin-bottom: 64px;
  align-items: flex-end;
}

.footer__info-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.footer__info-other {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  align-items: flex-end;
}

@media (max-width: 1500px) {
  .footer {
    margin-top: 160px;
  }

  .footer__link {
    font-size: 40px;
    gap: 37px;
    margin-top: 230px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .footer__info {
    margin-top: 220px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 25px;
  }

  .footer__info-links {
    gap: 5px;
    font-size: 10px;
  }

  .footer__info-other {
    gap: 5px;
    font-size: 10px;
  }

}

@media (max-width: 1200px) {
  .footer {
    margin-top: 143px;
  }


  .footer__link {
    font-size: 24px;
    gap: 24px;
    margin-top: 393px;
    align-items: center;
  }

  .footer__info-links {
    align-items: center;
    font-size: 13px;
    gap: 23px;
  }

  .footer__info {
    margin-top: 240px;
    margin-left: 64px;
    margin-right: 64px;
    margin-bottom: 30px;
    align-items: center;
    flex-direction: column;
    gap: 92px;
  }

  .footer__info-other {
    align-items: center;
    gap: 8px;
    font-size: 10px;
    color: #ffffff9e;
  }


}

@media (max-width: 768px) {
  .footer {
    margin-top: 160px;
  }


  .footer__link {
    font-size: 20px;
    gap: 20px;
    margin-top: 240px;
  }

  .footer__info {
    margin-top: 200px;
    margin-left: 0px;
    margin-right: 0px;
    gap: 64px;
  }

  .footer__info-links {
    font-size: 13px;
    gap: 11px;
  }


}