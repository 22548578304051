.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main__img {
  width: 1450px;
  height: 1015px;
  background: url('/public/img/main-img.png');
  background-size: cover;
  /* щоб зображення покривало весь елемент */
  background-position: center;
  /* щоб центр зображення співпадав з центром елемента */
  background-repeat: no-repeat;
  /* щоб зображення не повторювалось */
  margin-top: 96px;
}

.main__img-title {
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 77px;
  padding-top: 88px;
}

.main__img-button {
  padding: 25px 40px;
  border: 1px solid rgb(73, 72, 72);
  background: black;
  margin-top: 692px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-size: 12px;
  letter-spacing: 0.16em;
  width: fit-content;
  text-transform: uppercase;
  cursor: pointer;
}

.main__special {
  margin-top: 40px;
  margin-bottom: 200px;
}

.main__special-title {
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.main__special-description {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.main__special-img {
  width: 1400px;
  height: 868px;
  background: url('/public/img/special.png');
  background-size: cover;
  /* щоб зображення покривало весь елемент */
  background-position: center;
  /* щоб центр зображення співпадав з центром елемента */
  background-repeat: no-repeat;
  /* щоб зображення не повторювалось */
  margin-left: auto;
  margin-right: auto;
  background-color: #FFFFFF0D;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__special-img-title {
  font-size: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  width: 80%;
  font-weight: 300;
}

.main__special-img-description {
  font-size: 11px;
  font-weight: 300;
  line-height: 18.85px;
  letter-spacing: 0.05em;
  text-align: center;
  width: 70%;
  text-transform: uppercase;
}

.main__special-img-wrapper {
  width: 476px;
  height: 476px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 40px;
  flex-direction: column;
}

.main__car {
  width: 100%;
  height: 945px;
  position: relative;
  display: flex;
  justify-content: center;
}

.main__car-img {
  position: absolute;
  right: 0;
  height: 800px;
  width: 1300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__car-img-left {
  left: 0;
}

.main__car-info {
  width: 1400px;
}

.main__car-info-wrapper {
  display: flex;
}

.main__car-info-wrapper-left {
  justify-content: flex-end;
}

.main__car-info-icon {
  height: 48px;
  margin-right: 40px;
}

.main__car-info-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 40%;
}

.main__car-info-text-title {
  font-size: 40px;
  line-height: 58px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.main__car-info-text-description {
  font-size: 14px;
  line-height: 20.3px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.main__car-info-arrow {
  min-width: 59px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 32px;
}

@media (max-width: 1500px) {
  .main__img {
    width: 1173px;
    height: 800px;
    margin-top: 64px;
  }

  .main__img-title {
    font-size: 14px;
    margin-left: 77px;
    padding-top: 80px;
  }

  .main__img-button {
    padding: 20px 30px;
    margin-top: 520px;
    font-size: 10px;
  }

  .main__special {
    margin-top: 40px;
    margin-bottom: 160px;
  }

  .main__special-title {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .main__special-description {
    font-size: 12px;
    margin-bottom: 40px;
  }

  .main__special-img {
    width: 1080px;
    height: 662px;
  }

  .main__special-img-title {
    font-size: 13px;
  }

  .main__special-img-description {
    font-size: 10px;
  }

  .main__special-img-wrapper {
    width: 360px;
    height: 365px;
    gap: 30px;
  }

  .main__car {
    height: 800px;
  }

  .main__car-info {
    width: 1080px;
  }

  .main__car-img {
    height: 710px;
    width: 975px;
  }

  .main__car-info-icon {
    height: 40px;
    margin-right: 24px;
  }

  .main__car-info-text {
    gap: 24px;
    width: 45%;
    margin-right: 20px;
  }

  .main__car-info-text-title {
    font-size: 32px;
    line-height: 46.4px;
  }

  .main__car-info-text-description {
    font-size: 13px;
    line-height: 18.85px;
  }

  .main__car-info-wrapper {
    margin-top: -40px;
  }
}

@media (max-width: 1200px) {
  .main__img-title {
    display: none;
  }

  .main__img {
    height: 70vw;
    width: 90vw;
    background: url('/public/img/main-img-ipad.png');
    background-size: contain;
    margin-top: 96px;
  }

  .main__img-button {
    margin-top: 55vw;
  }

  .main__special {
    margin-top: 20px;
    margin-bottom: 100px;
  }

  .main__car-info-arrow {
    height: 40px;
  }

  .main__special-img {
    width: 753px;
    height: 443px;
  }

  .main__special-title {
    font-size: 20px;
  }

  .main__special-img-wrapper {
    width: 244px;
    height: 250px;
    gap: 30px;
  }

  .main__special-img-title {
    font-size: 12px;
    width: 95%;
  }

  .main__special-img-description {
    font-size: 8px;
    width: 95%;
  }

  .main__car-info-text-description {
    display: none;
  }

  .main__car-info-wrapper {
    margin-top: 0px;
  }

  .main__car-info {
    width: 90vw;
  }

  .main__car {
    height: 640px;
  }

  .main__car-info-text {
    width: fit-content;
    margin-right: 0px;
  }

  .main__car-info-icon {
    height: 44px;
  }

  .main__car-info-text-title {
    font-size: 20px;
    line-height: 29px;
  }

  .main__car-img {
    height: 590px;
    width: 90vw;
  }

  .news__title {
    width: auto;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .news__description {
    font-size: 12px;
    width: auto;
    margin-bottom: 20px;
  }

  .news__wrapper {
    width: 90vw;
  }

  .news__info {
    width: 70vw;
    height: 50vw;
  }

  .news__info-title {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .news__info-text {
    bottom: 0px;
    width: 100%;
    font-size: 12px;
    line-height: 17.4px;
  }

  .news__arrow {
    width: 53px;
    height: 32px;
  }

  .news__info-description {
    font-size: 10px;
  }

  .news__info-date {
    font-size: 12px;
    line-height: 17.4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
    right: auto;
    left: 0;
  }

}

@media (max-width: 768px) {
  .main__img {
    height: 80vw;
    width: 100vw;
    margin-top: 155px;
  }

  .main__img-button {
    margin-top: 74vw;
    padding: 24px 48px;
  }

  .main__special-img {
    width: 100vw;
    height: 165vw;
    background: url('/public/img/special-mobile.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .main__special-img-wrapper {
    width: 65vw;
    height: 61vw;
    gap: 20px;
    margin-top: 52vw;
  }

  .main__special {
    margin-top: 130px;
  }

  .main__special-title {
    display: none;
  }

  .main__special-img-title {
    font-size: 14px;
  }

  .main__special-img-description {
    font-size: 10px;
  }

  .main__special-description {
    display: none;
  }

  .main__car-img {
    height: 72vw;
    width: 95vw;
    background-size: contain;
  }

  .main__car {
    height: 90vw;
    justify-content: flex-end;
  }

  .main__car-info-icon {
    height: 24px;
    margin-right: 16px;
  }

  .main__car-info-arrow {
    min-width: 30px;
    height: 25px;
    position: absolute;
    right: 20px;
  }

  .main__car-info-icon-bentley {
    width: 75px;
  }

  .main__car-info-icon-bentley-mercedes {
    width: 24px;
  }

  .main__car-info-wrapper {
    margin-top: -20px;
  }

  .main__car-info-text-title {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  .main__car-info-wrapper-left {
    margin-right: 10px;
    justify-content: flex-start;
  }

  .main__car-info {
    width: 95vw;
  }

  .news {
    margin-top: 100px;
  }

  .news__title {
    margin-bottom: 16px;
  }

  .news__description {
    font-size: 10px;
    margin-bottom: 30px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .news__wrapper {
    height: 65vw;
    width: 100vw;
    position: relative;
  }

  .news__info {
    width: 100vw;
    height: 70vw;
    position: absolute;
  }

  .news__arrow {
    width: 30px;
    height: 24px;
    z-index: 1;
    margin-right: 10px;
  }

  .news__arrow-left {
    transform: scaleX(-1);
    margin-left: 10px;
  }

  .news__info-date {
    font-size: 12px;
    gap: 3px;
    left: 20px;
  }

  .news__info-title {
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .news__info-description {
    font-size: 10px;
    margin-left: 20px;
  }

}

@media (max-width: 576px) {
  .news__info-text {
    bottom: -20vw;
  }

  .main__car-info-text {
    width: 50%;
  }
}