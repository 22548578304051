.item {
  overflow-x: hidden;
  width: 100%;
}

.item__img {
  width: 100%;
  height: 100vh;
}

.item__img-item {
  object-position: bottom;
}

.item__title {
  font-weight: 850;
  letter-spacing: 0.08em;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 12.5%, rgba(255, 255, 255, 0.03) 84.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 15vw;
  position: relative;
  top: -180px;
  line-height: 1;
  text-align: center;
  width: 100%;
}

.item__content {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -250px;
  margin-bottom: 160px;
}

.item__content-logo {
  width: 480px;
  display: flex;
  justify-content: center;
}

.item__content-logo-img {
  height: 125px;
  object-fit: contain;
}

.item__content-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item__content-info-text {
  width: 480px;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.item__content-info-text-img {
  width: 740px;
}

.item__wrapper {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item__details-title {
  font-size: 40px;
  font-weight: 850;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 117px;
  margin-right: auto;
}

.item__details-wrapper {
  display: flex;
  gap: 65px;
  width: 100%;
}

.item__details-img {
  min-width: 887px;
  max-width: 887px;
  height: 595px;
  cursor: pointer;
}

.item__details-info-main {
  display: flex;
  gap: 16px;
  flex-direction: column;
  text-transform: uppercase;
  cursor: pointer;
}

.item__details-info-main-item {
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #FFFFFF80;
  transition: .2s;
  white-space: nowrap;
}

.item__details-info-main-active {
  /* font-size: 20px; */
  color: #FFFFFF;
  scale: 1.05;
}

.item__characteristic-title {
  font-size: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  margin-top: 200px;
  margin-bottom: 16px;
}

.item__characteristic-description {
  font-size: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 64px;
}

.item__characteristic-wrapper {
  display: flex;
  width: 1400px;
  justify-content: center;
}

.item__characteristic-wrapper-item {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 22px;
  text-transform: uppercase;
}


.item__characteristic-wrapper-item:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.item__characteristic-wrapper-title {
  font-size: 36px;
  letter-spacing: 0.05em;
}

.item__characteristic-wrapper-description {
  font-size: 14px;
  letter-spacing: 0.05em;
}

.item__info {
  width: calc(100% - 100px);
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.item__info-wrapper {
  display: flex;
  gap: 40px;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.item__info p:first-of-type {
  color: #FFFFFFB2;
  text-wrap: nowrap;
}

.item__info p:last-of-type {
  width: fit-content;
  text-wrap: nowrap;
  text-align: right;
  line-height: 150%;
}

.item__info-wrapper-line {
  width: 100%;
  height: 2px;
  background-color: #D9D9D933;
}

.item__gallery {
  display: flex;
  gap: 55px;
  margin-top: 200px;
  flex-direction: column;
  align-items: center;
}

.item__gallery-wrapper {
  display: flex;
  gap: 26px;
  cursor: pointer;
}

.item__gallery-left {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.item__gallery-left-img {
  width: 462px;
  height: 358px;
}

.item__gallery-navigate {
  display: flex;
  gap: 16px;
}

.item__gallery-navigate-item {
  width: 12px;
  height: 12px;
  background-color: #FFFFFF1A;
  cursor: pointer;
}

.item__gallery-navigate-item-active {
  background-color: #FFFFFF;
}

.item__video {
  margin-bottom: 200px;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item__video-title {
  font-weight: 850;
  letter-spacing: 0.08em;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 12.5%, rgba(255, 255, 255, 0.03) 84.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12vw;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  width: 100%;
}


.item__video-description {
  font-size: 40px;
  font-weight: 850;
  letter-spacing: 0.1em;
  text-align: left;
  width: 1400px;
  text-transform: uppercase;
  position: relative;
  top: -100px;
  margin-bottom: 20px;
}

.item__video-video {
  width: 1400px;
}

@media (max-width: 1500px) {
  .item__title {
    font-size: 16vw;
    top: -180px;
  }

  .item__content {
    width: 1080px;
    margin-bottom: 144px;
  }

  .item__content-logo {
    width: 275px;
  }

  .item__content-info-text {
    width: 275px;
    font-size: 13px;
    gap: 23px;
  }

  .item__content-info-text-img {
    width: 475px;
  }

  .item__wrapper {
    width: 1080px;
  }

  .item__details-title {
    font-size: 32px;
    margin-bottom: 64px;
  }

  .item__details-img {
    min-width: 687px;
    max-width: 687px;
    height: 458px;
  }

  .item__details-info-main {
    gap: 10px;
  }

  .item__details-info-main-item {
    font-size: 13px;
    white-space: wrap;
  }

  .item__details-info-main-active {
    scale: 1.05;
  }

  .item__characteristic-wrapper {
    width: 1080px;
  }

  .item__details-wrapper {
    gap: 50px;
  }

  .item__characteristic-title {
    font-size: 32px;
    margin-top: 160px;
  }

  .item__characteristic-wrapper-title {
    font-size: 24px;
  }

  .item__characteristic-wrapper-item {
    height: 140px;
    gap: 18px;
  }

  .item__characteristic-wrapper-description {
    font-size: 12px;
  }

  .item__gallery-left-img {
    width: 347px;
    height: 268px;
  }

  .item__gallery-navigate-item {
    position: relative;
    width: 10px;
    height: 10px;
  }

  .item__gallery-navigate-item::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }

  .item__video {
    margin-bottom: 160px;
    margin-top: 120px;
  }

  .item__video-description {
    font-size: 32px;
    width: 1080px;
    top: -80px;
    margin-bottom: 20px;
  }

  .item__video-video {
    width: 1080px;
  }

  .item__info {
    width: 100%;
    margin-top: 160px;
    gap: 54px;
  }

  .item__info-wrapper {
    font-size: 12px;
  }

  .item__gallery {
    margin-top: 160px;
  }

}

@media (max-width: 1200px) {
  .item__content {
    width: 80vw;
  }

  .item__wrapper {
    width: calc(100vw - 80px);
  }
}

@media (max-width: 1024px) {
  .item__content-info-text-img {
    width: 320px;
  }

  .item__img {
    height: 547px;
  }

  .item__content-logo {
    width: 90vw;
    justify-content: flex-end;
    margin-bottom: 40px;
  }

  .item__characteristic-wrapper-title {
    font-size: 20px;
  }

  .item__content-logo-img {
    width: 330px;
    height: 65px;
  }

  .item__title {
    top: -90px;
  }

  .item__content-info {
    flex-direction: row-reverse;
  }

  .item__content {
    margin-top: -144px;
    margin-bottom: 110px;
    width: 90vw;

  }

  .item__content-info-text {
    width: 330px;
    font-size: 11px;
    gap: 40px;
  }

  .item__details-title {
    font-size: 20px;
    margin-bottom: 44px;
  }

  .item__details-img {
    min-width: 60vw;
    max-width: 60vw;
    height: 40vw;
  }

  .item__details-wrapper {
    gap: 28px;
  }

  .item__details-info-main {
    gap: 10px;
  }

  .item__details-info-main-item {
    font-size: 10px;
  }

  .item__details-info-main-active {
    scale: 1.05;
  }

  .item__characteristic-title {
    font-size: 20px;
    margin-top: 104px;
  }

  .item__characteristic-description {
    font-size: 12px;
  }

  .item__characteristic-wrapper {
    width: calc(100vw - 80px);
  }

  .item__characteristic-wrapper-item {
    height: 140px;
    gap: 16px;
  }

  .item__characteristic-wrapper-description {
    font-size: 10px;
    width: 70%;
    text-align: center;
  }

  .item__gallery {
    gap: 56px;
    margin-top: 120px;
  }

  .item__gallery-left {
    gap: 20px;
  }

  .item__gallery-wrapper {
    gap: 20px;
  }

  .item__gallery-left-img {
    width: 30vw;
    height: 20vw;
  }

  .item__colors {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .item__colors-wrapper {
    width: 80vw
  }

  .item__colors-img {
    height: 50vw;
    margin-bottom: 33px;
  }

  .item__colors-title {
    font-size: 20px;
    margin-bottom: 35px;
  }

  .item__colors-discription {
    font-size: 10px;
  }

  .item__video-description {
    font-size: 20px;
    width: calc(100vw - 80px);
    top: -45px;
    margin-bottom: 0px;
  }

  .item__video-video {
    width: calc(100vw - 80px);
  }

  .item__video {
    margin-bottom: 120px;
    margin-top: 120px;
  }

  .item__info {
    margin-top: 120px;
    gap: 36px;
  }

  .item__info-wrapper {
    font-size: 10px;
  }

}

@media (max-width: 768px) {
  .item__wrapper {
    width: 100vw;
  }

  .item__img {
    height: 60vh;
  }

  .item__content {
    width: calc(100vw - 80px);
    margin-top: -80px;
    margin-bottom: 70px;
  }

  .item__content-logo-img {
    width: 28vw;
  }

  .item__title {
    font-size: 17vw;
    top: -50px;
  }

  .item__content-logo {
    width: 100%;
    margin-bottom: 56px;
    justify-content: center;
  }

  .item__content-info {
    flex-direction: column;
    gap: 70px;
  }

  .item__content-info-text {
    width: 100%;
  }

  .item__details-title {
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }

  .item__details-wrapper {
    flex-direction: column;
    /* align-items: center; */
    gap: 65px;
  }

  .item__details-img {
    min-width: 100vw;
    max-width: 100vw;
    height: 65vw;
  }

  .item__colors-wrapper {
    width: 100vw;
  }

  .item__colors-img {
    height: 65vw;
    margin-bottom: 33px;
  }

  .item__colors-title {
    font-size: 14px;
    margin-left: 20px;
  }

  .item__colors-discription {
    font-size: 10px;
    width: 80%;
  }

  .item__details-info-main {
    gap: 16px;
    margin-left: 20px;
  }

  .item__details-info-main-item {
    font-size: 11px;
  }

  .item__content-info-text-img {
    width: 55vw;
  }

  .item__details-info-main-active {
    scale: 1.03;
  }

  .item__characteristic-title {
    margin-bottom: 10px;
  }

  .item__characteristic-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .item__characteristic-description {
    font-size: 12px;
    margin-bottom: 40px;
  }

  .item__characteristic-wrapper-item:nth-child(2) {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;
  }

  .item__characteristic-wrapper-item {
    height: 180px;
    gap: 24px;
    width: 90%;
  }

  .item__gallery {
    gap: 56px;
    margin-top: 100px;
  }

  .item__gallery-wrapper {
    gap: 12px;
    flex-direction: column-reverse;
  }

  .item__gallery-left {
    flex-direction: row;
    gap: 12px;
  }

  .item__gallery-left-img {
    width: calc(50vw - 6px);
    height: 32vw;
  }

  .item__gallery-right {
    width: 100vw;
    height: 65vw;
  }

  .item__video-video {
    width: 100vw;
    margin-top: 32px;
  }

  .item__video-description {
    top: -27px;
    width: calc(100vw - 40px);
    font-size: 16px;
  }

  .item__video {
    margin-bottom: 104px;
  }

  .item__info {
    width: calc(100% - 40px);
    margin-top: 80px;
    gap: 36px;
  }

  .item__info-wrapper {
    font-size: 10px;
    flex-direction: column;
  }

  .item__info-wrapper-line {
    display: none;
  }

  .item__info p:last-of-type {
    text-align: center;
      font-size: 12px;
  }

  .item__info-wrapper {
    padding-bottom: 24px;
    border-bottom: 1.5px solid #D9D9D933;
    gap: 20px;
  }

}