.links {
  margin-top: 144px;
  width: 100%;
}

.links__title {
  font-size: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 100px;
}

.links__wrapper {
  display: flex;
  gap: 40px;
  padding: 10px;
}

.links__item {
  width: 440px;
  transition: .5s;
}

.links__item:hover {
  scale: 1.05;
}

.links__item-img {
  margin-bottom: 25px;
  height: 270px;
}

.links__item-title {
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.links__item-description {
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 70%;
}

@media (max-width: 1500px) {

  .links__item-img {
    margin-bottom: 25px;
    height: 220px;
  }

  .links__item {
    width: 332px;
  }

  .links__item-title {
    font-size: 13px;
    margin-bottom: 16px;
  }

  .links__item-description {
    font-size: 11px;
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .links {
    width: calc(100vw - 40px);
  }

  .links__title {
    font-size: 20px;
    margin-bottom: 64px;
  }

  .links__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 40px;
  }

  .links__item {
    min-width: 332px;
  }
}

@media (max-width: 768px) {
  .links {
    width: 100%;
  }

  .links__title {
    font-size: 16px;
    margin-bottom: 56px;
    margin-left: 20px;
  }

  .links__wrapper {
    gap: 0px;
  }

  .links__item:first-child {
    margin-left: 20px;
  }

}