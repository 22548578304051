@font-face {
    font-family: 'Garet';
    font-style: normal;
    font-weight: 300;
    src: local('Garet'), url('../public/fonts/Garet-Book.ttf') format('truetype');
}

@font-face {
    font-family: 'Garet';
    font-style: normal;
    font-weight: 700;
    src: local('Garet'), url('../public/fonts/Garet-Heavy.ttf') format('truetype');
}

body {
    font-family: 'Garet', sans-serif;
    font-weight: 300;
}

textarea,
option,
select,
button,
input,
input::placeholder {
    font-family: 'Garet', sans-serif;
}