*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #0F0F0F;
  color: #FFFFFF;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

ul,
li {
  list-style: none;
}

a,
button {
  text-decoration: none !important;
  color: inherit;
  border: none;
}

input {
  border-radius: 0px;
}

textarea,
select,
input:active,
input:focus {
  outline: none;
}

.text-no-touch {
  -webkit-user-select: none;
  /* для браузеров, использующих движок WebKit (Chrome, Safari, etc.) */
  -moz-user-select: none;
  /* для браузеров, использующих движок Gecko (Firefox, etc.) */
  -ms-user-select: none;
  /* для браузеров, использующих движок Trident (Internet Explorer) */
  user-select: none;
}