.header {
  display: flex;
  justify-content: space-between;
  padding: 40px 64px;
  background: linear-gradient(180deg, #0f0f0f, #22262900);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}

.header__burger-img {
  display: none;
}

.header__left {
  display: flex;
  gap: 65px;
  align-items: center;
}

.header__left-logo {
  width: 180px;
  height: 48px;
  position: relative;
  top: -17px;
}

.header__left-nav {
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  display: flex;
  gap: 40px;
}

.header__left-nav-item {
  position: relative;
  cursor: pointer;
}

.header__left-nav-item:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
  animation: title .2s linear forwards;
}

@keyframes title {
  0% {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  100% {
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}

.header__left-nav-item-links {
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: none;
  width: 400px;
  background-color: #0f0f0f;
}

.header__left-nav-item-links>li {
  padding: 10px;
}

.header__left-nav-item-links>li:hover {
  background-color: #232222;
}

.header__right {
  display: flex;
  gap: 65px;
  align-items: center;
}

.header__right-tel {
  font-size: 16px;
}

.header__right-language {
  display: flex;
  gap: 20px;
  color: #FFFFFF66;
  cursor: pointer;
}

.header__right-language-item {
  color: #FFFFFF66;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
}

.header__right-language-active {
  color: white;
  text-decoration: underline;
  text-underline-offset: 10px;
}

.header__burger-black {
  display: none;
}

.header__burger-window {
  display: none;
}

@media (max-width: 1500px) {
  .header {
    padding: 30px 40px;
  }

  .header__left {
    gap: 40px;
  }

  .header__left-logo {
    width: 150px;
    height: 40px;
    top: -15px;
  }

  .header__left-nav {
    font-size: 11px;
    gap: 32px;
  }

  .header__right {
    gap: 50px;
  }

  .header__right-language {
    gap: 12px;
  }

  .header__right-tel {
    font-size: 13px;
  }

  .header__right-language-item {
    font-size: 11px;
  }

  .header__left-nav-item-links {
    top: 30px;
  }
}

@media (max-width: 1024px) {
  .header {
    padding: 48px 41px;
    align-items: center;
  }

  .header__left-logo {
    top: -10px;
  }

  .header__left-nav {
    display: none;
  }

  .header__right {
    display: none;
  }

  .header__burger-img {
    display: block;
    width: 20px;
    height: 16px;
  }

  .header__burger-black {
    position: fixed;
    display: block;
    z-index: 1;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.572);
    opacity: 0;
    transition: .3s;
  }

  .header__burger-black-active {
    opacity: 1;
    pointer-events: auto;
  }

  .header__burger-window {
    position: absolute;
    display: block;
    opacity: 0;
    transition: .3s;
    pointer-events: none;
    z-index: 2;
    right: 0;
    top: 0;
    width: 45vw;
    height: 100vh;
    background-color: #0F0F0F;
    padding: 48px 20px;
  }

  .header__burger-window-active {
    opacity: 1;
    pointer-events: auto;
  }

  .header__burger-window-open-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__left-logo-close {
    display: block;
    width: 23px;
    height: 23px;
    margin-right: 21px;
  }

  .header__burger-window-nav {
    margin-top: 105px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 6px;
    font-size: 14px;
  }

  .header__burger-window-nav-item {
    font-size: 14px;
    text-transform: uppercase;
  }

  .header__right-tel {
    font-size: 20px;
    margin-top: 105px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .header__right-language {
    margin-top: 32px;
    gap: 19px;
  }

  .header__right-language-item {
    font-size: 12px;
  }

}

@media (max-width: 768px) {
  .header {
    padding: 48px 20px 70px 20px;
  }

  .header__burger-img {
    width: 20px;
    height: 20px;
  }

  .header__burger-window {
    width: 100vw;
  }

  .header__left-logo {
    width: 132px;
    height: 35px;
    top: -7px;
  }

  .header__left-logo-close {
    margin-right: 0px;
  }

  .header__burger-black {
    display: none;
  }

  .header__burger-window-nav {
    margin-top: 50px;
  }

  .header__right-tel {
    margin-top: 60px;
  }

}