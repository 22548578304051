.news {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news__title {
  width: 1078px;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 10px;
}

.news__description {
  font-size: 13px;
  width: 1078px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 80px;
}

.news__wrapper {
  display: flex;
  align-items: center;
  width: 1400px;
  justify-content: space-between;
}

.news__arrow {
  background-color: inherit;
  border: none;
  cursor: pointer;
  width: 69px;
  height: 54px;
}

.news__arrow-left {
  transform: scaleX(-1);
}

.news__info {
  width: 940px;
  height: 650px;
  position: relative;
}

.news__info-date {
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: right;
  z-index: 1;
  position: absolute;
  right: 0;
}

.news__info-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.news__info-img-black {
  position: absolute;
  /* top: -1px;
  left: -2px;
  width: 101%;
  height: 101%; */
}

.news__info-text {
  z-index: 1;
  position: absolute;
  text-transform: uppercase;
  bottom: 40px;
  width: 35%;
}

.news__info-title {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-align: left;
  margin-bottom: 25px;
}

.news__info-description {
  font-size: 13px;
  line-height: 18.85px;
  letter-spacing: 0.05em;
}

@media (max-width: 1500px) {
  .news {
    margin-top: 160px;
  }

  .news__wrapper {
    width: 1200px;
  }
}