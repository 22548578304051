.notFound {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.notFound::before {
  content: "";
  background: url(../../../public/img/404.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 25%;
  /* This will position the image in the center horizontally */
  width: 50%;
  height: 100%;
}

.notFound__text {
  font-family: Garet;
  font-size: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 20vh;
  text-transform: uppercase;
}

.notFound__button {
  padding: 35px 70px;
  border: 1px solid rgb(73, 72, 72);
  color: white;
  margin-top: 60vh;
  text-transform: uppercase;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background: #0000006b;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 768px) {
.notFound__text {
    font-size: 12px;
  }

.notFound__button {
  font-size: 12px;
}


}