.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__img {
  width: 100%;
  height: 800px;
}

.about__title {
  font-weight: 850;
  letter-spacing: 0.08em;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 12.5%, rgba(255, 255, 255, 0.03) 84.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 17vw;
  position: relative;
  top: -180px;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  width: 100%;
}

.about__info {
  margin-top: -85px;
  margin-bottom: 200px;
  width: 1400px;
}

.about__info-logo {
  height: 110px;
  margin-top: -270px;
  margin-bottom: 100px;
}

.about__info-wrapper {
  display: flex;
  gap: 100px;
  align-items: center;
  margin-bottom: 200px;
}

.about__info-wrapper-img {
  height: 860px;
  width: 837px;
}

.about__info-wrapper-info {
  width: 490px;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  gap: 64px;
  flex-direction: column;
}

.about__info-wrapper-info-button {
  border: 1px solid rgb(73, 72, 72);
  background: black;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-size: 12px;
  letter-spacing: 0.16em;
  margin-top: 64px;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92px;
}

.about__ansver {
  width: 890px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
}

.about__ansver-title {
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 90px;
  text-transform: uppercase;
}

.about__ansver-wrapper {
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  gap: 64px;
  flex-direction: column;
}

.about__ansver-wrapper>p {
  border-bottom: 1px solid white;
  padding-bottom: 16px;
}

.about__next {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.about__next-wrapper {
  display: flex;
  gap: 50px;
  flex-direction: column;
}

.about__next-block {
  display: flex;
  padding-bottom: 50px;
}

.about__next-block:first-child {
  border-bottom: 1px solid white;
}

.about__next-block-item-left {
  padding-right: 95px;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.about__next-block-item {
  padding-left: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.about__next-block-item-number {
  font-size: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.about__next-block-item-title {
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.about__next-block-item-description {
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.about__next-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1500px) {
  .about__img {
    height: 534px;
  }

  .about__info-wrapper {
    margin-bottom: 160px;
  }

  .about__info-wrapper-img {
    height: 600px;
    width: 618px;
  }

  .about__info-logo {
    height: 87px;
    margin-top: -100px;
    margin-bottom: 30px;
  }

  .about__info {
    margin-top: -150px;
    margin-bottom: 160px;
    width: 1080px;
  }

  .about__info-wrapper-info {
    width: 348px;
    font-size: 13px;
    gap: 32px;
  }

  .about__info-wrapper-info-button {
    font-size: 10px;
    height: 75px;
    margin-top: 56px;
  }

  .about__next {
    width: 1080px;
  }

  .about__ansver {
    width: 800px;
    margin-bottom: 160px;
  }

  .about__ansver-title {
    font-size: 32px;
    margin-bottom: 80px;
  }

  .about__ansver-wrapper {
    font-size: 14px;
    gap: 55px;
  }

  .about__ansver-wrapper>p {
    padding-bottom: 10px;
  }

  .about__ansver-title {
    font-size: 32px;
    margin-bottom: 80px;
  }

  .about__next-block-item-number {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .about__next-block-item-title {
    font-size: 16px;
    margin-bottom: 11px;
  }

  .about__next-block-item-description {
    font-size: 12px;
  }

  .about__next-block {
    padding-bottom: 38px;
  }

  .about__next-wrapper {
    gap: 38px;
  }

  .about__next-inputs {
    width: 1080px;

  }

}

@media (max-width: 1140px) {
  .about__info {
    width: 90vw;
  }

  .about__info-wrapper-img {
    height: 42vw;
    width: 43vw;
  }

  .about__info-wrapper {
    gap: 50px;
    justify-content: center;
    margin-bottom: 260px;
  }

  .about__next {
    width: 800px;
  }

  .about__next-inputs {
    width: 900px;
  }

}

@media (max-width: 1024px) {
  .about__img {
    height: 348px;
  }

  .about__info-wrapper-img {
    width: 46vw;
    height: 45vw;
  }

  .about__info {
    margin-top: -25px;
    margin-bottom: 55px;
    width: calc(100vw - 80px);
  }

  .about__title {
    font-size: 17vw;
    top: -60px;
  }

  .about__info-wrapper {
    gap: unset;
    position: relative;
    justify-content: space-around;
  }

  .about__info-wrapper-info {
    width: 300px;
    font-size: 11px;
    gap: 25px;
  }

  .about__info-logo {
    height: 50px;
    margin-top: -135px;
    margin-bottom: 45px;
  }

  .about__info-wrapper-info-button {
    font-size: 10px;
    height: 60px;
    margin-top: 56px;
    position: absolute;
    bottom: -165px;
    left: 0;
    right: 0;
    width: 312px;
  }

  .about__ansver {
    width: calc(100vw - 80px);
    margin-bottom: 120px;
  }

  .about__ansver-title {
    font-size: 20px;
    margin-bottom: 64px;
  }

  .about__ansver-wrapper {
    font-size: 13px;
    gap: 55px;
  }

  .about__next {
    width: calc(100vw - 80px);
  }

  .about__next-block-item-number {
    font-size: 32px;
    margin-bottom: 12px;
  }

  .about__next-block-item-title {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .about__next-block-item {
    height: 250px;
    padding-left: 52px;
    width: 100%;
  }

  .about__next-block-item-left {
    padding-right: 52px;
  }

  .about__next-inputs {
    width: calc(100vw - 80px);
  }

}

@media (max-width: 768px) {
  .about__img {
    height: 460px;
  }

  .about__info {
    margin-top: 30px;
    width: calc(100vw - 40px);
  }

  .about__info-wrapper {
    gap: 75px;
    flex-direction: column-reverse;
    margin-bottom: 120px;
  }

  .about__info-wrapper-info {
    width: calc(100vw - 80px);
    gap: 40px;
  }

  .about__info-logo {
    margin-top: -114px;
    margin-bottom: 20px;
    height: 45px;
  }

  .about__info-wrapper-img {
    width: 100vw;
    height: 100vw;
  }

  .about__info-wrapper-info-button {
    position: static;
    margin-top: 30px;
    width: 100%;
  }


  .about__ansver {
    width: 100%;
    margin-bottom: 120px;
  }

  .about__ansver-wrapper {
    font-size: 12px;
    gap: 55px;
  }

  .about__next-block {
    flex-direction: column;
  }

  .about__next-block-item-left {
    border-right: none;
    padding-right: 0px;
    border-bottom: 1px solid white;
    padding-bottom: 80px;
  }

  .about__next-block-item-number {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .about__next-block-item-title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .about__next-block-item {
    padding-left: 0px;
    align-items: flex-end;
    text-align: right;
    height: auto;
    padding-bottom: 80px;
    margin-top: 80px;
  }

  .about__next-block {
    padding-bottom: 0px;
  }

  .about__next {
    width: 100%;
  }

  .about__next-inputs {
    width: 100%;
  }

  .about__title {
    font-size: 18vw;
  }
}