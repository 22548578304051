.privacy {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
      color: #ffffffc7;
}

.privacy__title {
  font-size: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 156px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 64px;
}

.privacy>h2 {
  font-size: 20px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 64px;
}

.privacy>p {
  font-size: 16px;
  font-weight: 300;
  line-height: 20.8px;
  text-align: left;
}

.privacy a {
  text-decoration: underline !important;
}

.privacy li {
  margin-left: 24px;
  list-style: disc !important;
  margin-top: 5px;
  font-size: 16px;
}

@media (max-width: 1500px) {
  .privacy {
    width: 1080px;
  }

  .privacy__title {
    font-size: 24px;
  }

  .privacy>h2 {
    font-size: 16px;
  }

  .privacy>p {
    font-size: 14px;
  }

  .privacy li {
    font-size: 14px;
  }
}

@media (max-width: 1124px) {
  .privacy__title {
    margin-bottom: 34px;
  }

  .privacy {
    width: 85vw;
  }

  .privacy__title {
    font-size: 18px;
  }

  .privacy>h2 {
    font-size: 14px;
    Line-height: 20px;
    margin-top: 50px;
      margin-bottom: 20px;
  }

  .privacy>p {
    font-size: 12px;
  }

  .privacy li {
    font-size: 12px;
  }
}